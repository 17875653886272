import React, { ReactElement, useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";

import Icon from "components/Icon";
import Image from "components/Image";
import { useBreakpoint } from "hooks";
import { BREAKPOINT_NUMBER } from "style/breakpoints";
import generateSrcSet from "style/generateSrcSet";

import {
    Container,
    PublishDate,
    Categories,
    Heading,
    ImageWrapper,
    StyledWatermark,
    Link,
    Length,
    Content,
    Introduction,
    DateAndLength,
} from "./PageCard.styled";
import PageCardProps from "./PageCardProps";

const PageCard = ({
    categories,
    heading,
    startPublish,
    hideStartPublish,
    image,
    introduction,
    watermark,
    url,
    size = "small",
    length,
    className,
    prefetch = true,
    gtmClickType,
}: PageCardProps): ReactElement => {
    const [isSafari, setIsSafari] = useState<boolean>(false);

    if (image?.caption) delete image.caption;
    if (image && image.url)
        switch (size) {
            case "large":
                image.srcSet = generateSrcSet({
                    image: image.url,
                    focalPoint: image.focalPoint,
                    content: [
                        {
                            width: 1024,
                            height: Math.ceil(1024 / 1.5),
                            breakpoint: BREAKPOINT_NUMBER.XL,
                        },
                        {
                            width: 850,
                            height: Math.ceil(850 / 1.5),
                            breakpoint: BREAKPOINT_NUMBER.L,
                        },
                        {
                            width: 992,
                            height: Math.ceil(992 / 1.5),
                            breakpoint: BREAKPOINT_NUMBER.M,
                        },
                        {
                            width: 768,
                            height: Math.ceil(768 / 1.5),
                        },
                    ],
                    maxWidth: image.uploadedWidth,
                    maxHeight: image.uploadedHeight,
                    format: "jpg",
                });
                break;
            case "medium":
                image.srcSet = generateSrcSet({
                    image: image.url,
                    focalPoint: image.focalPoint,
                    content: [
                        {
                            width: 800,
                            height: Math.ceil(800 / 1.5),
                            breakpoint: BREAKPOINT_NUMBER.XL,
                        },
                        {
                            width: 600,
                            height: Math.ceil(600 / 1.5),
                            breakpoint: BREAKPOINT_NUMBER.L,
                        },
                        {
                            width: 500,
                            height: Math.ceil(500 / 1.5),
                            breakpoint: BREAKPOINT_NUMBER.M,
                        },
                        {
                            width: 768,
                            height: Math.ceil(768 / 1.5),
                        },
                    ],
                    maxWidth: image.uploadedWidth,
                    maxHeight: image.uploadedHeight,
                    format: "jpg",
                });
                break;
            default:
                image.srcSet = generateSrcSet({
                    image: image.url,
                    focalPoint: image.focalPoint,
                    content: [
                        {
                            width: 500,
                            height: Math.ceil(500 / 1.5),
                            breakpoint: BREAKPOINT_NUMBER.XL,
                        },
                        {
                            width: 450,
                            height: Math.ceil(450 / 1.5),
                            breakpoint: BREAKPOINT_NUMBER.M,
                        },
                        {
                            width: 768,
                            height: Math.ceil(768 / 1.5),
                        },
                    ],
                    maxWidth: image.uploadedWidth,
                    maxHeight: image.uploadedHeight,
                    format: "jpg",
                });
                break;
        }

    let iconSize: string;
    const breakPoint = useBreakpoint();
    switch (size) {
        case "large":
            switch (breakPoint) {
                case "XL":
                case "L":
                case "M":
                    iconSize = "140";
                    break;
                default:
                    iconSize = "56";
            }
            break;
        case "medium":
            switch (breakPoint) {
                case "XL":
                    iconSize = "140";
                    break;
                case "L":
                    iconSize = "105";
                    break;
                default:
                    iconSize = "56";
            }
            break;
        default:
            switch (breakPoint) {
                case "XL":
                    iconSize = "105";
                    break;
                default:
                    iconSize = "56";
            }
            break;
    }

    useEffect(() => {
        typeof navigator !== "undefined" &&
            setIsSafari(
                navigator.userAgent.indexOf("Safari") !== -1 &&
                    navigator.userAgent.indexOf("Chrome") === -1,
            );
    }, []);

    return (
        <ThemeProvider
            theme={{ size: size, showImage: !!image?.url, isSafari: isSafari }}
        >
            <Container className={className}>
                <Link
                    to={url}
                    title={heading}
                    exact
                    prefetch={prefetch}
                    gtmClickType={gtmClickType}
                >
                    <ImageWrapper>
                        {image?.url && <Image {...image} />}
                        {watermark && (
                            <StyledWatermark
                                key={`Watermark-${watermark}-${iconSize || ""}`}
                                icon={`${watermark}${iconSize}`}
                                variant={image?.url ? "white" : "color"}
                            />
                        )}
                    </ImageWrapper>
                    <Content>
                        {heading && (
                            <Heading>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: heading,
                                    }}
                                />
                            </Heading>
                        )}
                        {categories && <Categories>{categories}</Categories>}
                        <DateAndLength>
                            {startPublish && !hideStartPublish && (
                                <PublishDate>{startPublish}</PublishDate>
                            )}
                            {length && (
                                <Length>
                                    <Icon icon="clock28" /> {length}
                                </Length>
                            )}
                        </DateAndLength>
                        {introduction && size !== "small" && (
                            <Introduction>{introduction}</Introduction>
                        )}
                    </Content>
                </Link>
            </Container>
        </ThemeProvider>
    );
};

export default React.memo(PageCard);
